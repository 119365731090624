import {request} from "../utils/http";
import {IDepRequestResult, ITipRequestResult} from "./home";

export interface ISettingInfo {
  type: SettingType,
  value: string,
}

export interface ITipDepRestDto {
  tipRequest: ITipRequestResult;
  depRequest?: IDepRequestResult;
}

export function saveSetting(settingInfo: ISettingInfo) {
  return request<string>({
    url: '/admin/setting',
    method: 'POST',
    authRequired: true,
    data: settingInfo
  });
}

export function listTipRequests(pageRequest: PageRequestInfo, filter?: TipFilterInfo) {
  return request<PageInfo<ITipDepRestDto>>({
    url: '/admin/list-tips',
    method: 'POST',
    authRequired: true,
    data: {
      pageRequest: pageRequest,
      tipFilter: filter,
    }
  });
}